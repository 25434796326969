export const fontFamily = 'Lato';

export const backgroundColor = '#F8F8F8';
export const fontColorMenu = '#F8F8F8';
export const titleFontColor = '#2C2C2C';
export const menuIconsColor = 'white';
export const iconsColor = '#2C2C2C';
export const fontColor = '#363636';
export const dropSelectorColor = '#2C2C2C';
export const disabledColor = '#00000061';
export const tabGradientColor = '#F8F8F8' //Adicionar cor ao Side Menu dependendo do cliente

export const loginColor = '#2C2C2C';
export const logininputColor = '#2C2C2C';
export const loginErrorColor = 'red';
export const loginTermosDeUsoColor = '#2C2C2C';
export const loginBoxBGColor = '#F8F8F8';
export const loginBGColor = '#F8F8F8';
export const loginButtonBgc = '#118282';
export const loginButtonColor = '#FFFFFF';
export const loginBorder = 'none';
export const loginUnderlineColor = '#969696';

export const topMenuBoxShadow =
  '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)';

export const barChartColor = '#02D181';
export const lineChartColors = [
  '#02D181',
  '#22B8CF',
  '#F76707',
  '#F59F00',
  '#5C7CFA',
  '#7950F2',
  '#BE4BDB',
  '#DB4B90',
];

export const chartColors = [
  '#F76707',
  '#F59F00',
  '#02D181',
  '#22B8CF',
  '#5C7CFA',
  '#7950F2',
  '#BE4BDB',
  '#DB4B90',
];

export const chartLighterColors = [
  '#f9eae1',
  '#f8f0e0',
  '#e0f5ed',
  '#e3f2f5',
  '#e9ecf9',
  '#ece8f8',
  '#f3e7f6',
  '#f6e7ee',
];

export const chartLightColors = [
  '#f7dbc7',
  '#f7e6c6',
  '#c6f0e0',
  '#cdebef',
  '#d8dff8',
  '#ded6f6',
  '#ecd5f2',
  '#f2d5e3',
];

export const defaultLegendColor = '#D9D9D9';

export const fontSizeTitle = '24px';
export const xsFontSize = '10px';
export const smFontSize = '12px';
export const mdFontSize = '16px';
export const lgFontSize = '20px';
export const xlFontSize = '28px';
export const lineHeight125 = '125%';

export const lineColor = '#bebebe';
export const bgcMain = '#118282';
export const bgcMenuDivider = 'white';
export const bgcSecondary = '#FFFFFF';
export const borderRightMenu = '1px solid #118282';

export const orange = '#EF770C';
export const red = '#EF0C0C';
export const primaryColor = '#118282';
export const lightBlue = '#23B0DC';
export const positiveGreen = '#26A69A';
export const negativeRed = '#e73c3c';

export const tableCellColor = '#525252';
export const tableCellDarkerColor = '#2C2C2C';

export const borderRadius = '10px';

export const xsPadding = '8px';
export const smPadding = '16px';
export const mdPadding = '24px';
export const lgPadding = '32px';
export const xlPadding = '40px';

export const xsMargin = '8px';
export const smMargin = '16px';
export const mdMargin = '24px';
export const lgMargin = '32px';
export const xlMargin = '40px';

export const mobileS = '320px';
export const mobileM = '375px';
export const mobileL = '425px';
export const mobileXL = '490px';
export const tablet = '768px';
export const laptop = '1024px';
export const laptopL = '1440px';
export const desktop = '2560px';
