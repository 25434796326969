import React from 'react';
import { ReactComponent as PDDFSymbol } from '../../assets/PDFSymbol.svg';
import { useTheme } from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import { LineSmall } from '../Line';
import { Container, BotaoPDFContainer, BotaoPDFText } from './style';
import { useAppSelector } from '../../services/reduxHooks';
import { Skeleton } from '@mui/material';

export default function BotaoGerarPDF() {
  const { search, pathname } = useLocation();
  const theme = useTheme();
  const [
    isLoadedResumoCart,
    isLoadedResumoCartPerf, // apenas para azimut que usa percent na tabela sempre
    isLoadedPerfHist,
    isLoadedPosCon,
    // isLoadedCartExp,
    isLoadedFluxoAtivos,
    isLoadedEstats,
  ] = useAppSelector((state) => [
    state.resumoCart.isLoaded,
    state.resumoCart.isLoadedPercent, // apenas para azimut que usa percent na tabela sempre
    state.perfHist.isLoaded,
    state.posCon.isLoaded,
    // state.cartExp.isLoaded,
    state.fluxoAtivos.isLoaded,
    state.estats.isLoaded,
  ]);

  return (
    <Container>
      <BotaoPDFContainer>
        {isLoadedResumoCart &&
        isLoadedResumoCartPerf &&
        isLoadedPerfHist &&
        isLoadedPosCon &&
        // isLoadedCartExp &&
        isLoadedFluxoAtivos &&
        isLoadedEstats ? (
          <Link
            style={{
              textDecoration: 'none',
              color: 'inherit',
              alignItems: 'center',
              display: 'flex',
              gridGap: '5px',
            }}
            to={{ pathname: '/pdf', search }}
            state={pathname}>
            <PDDFSymbol width={22} height={22} fill={theme.iconsColor} />
            <BotaoPDFText>Gerar PDF</BotaoPDFText>
          </Link>
        ) : (
          <Skeleton width={60} style={{cursor: 'wait'}} />
        )}

        <LineSmall />
      </BotaoPDFContainer>
    </Container>
  );
}
