import { resetStateBuilder } from './helperReducers';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchResumoCart } from '../api/resumoCartAPI';
import { RootState } from '../store';
import { fetchResumoCartPerf } from '../api/resumoCartAPI';

interface IRetornoCart {
  descricao: string;
  mes: number | string | string;
  ano: number | string;
  m12: number | string;
  m24: number | string;
  periodo: number | string;
}
interface IRendPorClasse {
  classe: string;
  saldo: number | string;
  percent: number | string;
  mes: number | string;
  ano: number | string;
  cor: string;
}
interface IMovimentacoes {
  data: string;
  saldoMovim: number | string;
  saldoBruto: number | string;
}
interface AlocModal {
  nome_ativo: string;
  sbr_fim: number;
  alocacao: number;
}
interface IAlocCustodiante {
  custodiante: string;
  alocacao: number;
  ativos: AlocModal[];
}
interface IAlocClasse {
  classe: string;
  alocacao: number;
  ativos: AlocModal[];
}
export interface IResumoCartState {
  isLoaded?: boolean;
  isLoadedPercent?: boolean;
  loading?: boolean;
  obj_datas: {
    data_ini: string;
    data_fim: string;
    data_ini2: string;
  };
  resumo?: {
    saldoIni: number | string;
    saldoFim: number | string;
    impostospagos: number | string;
    rendimentoBruto: number | string;
    movimentacoes: number | string;
    datas: {
      data_ini: string;
      data_fim: string;
    };
  };
  retorno?: IRetornoCart[];
  rendPorClasse?: IRendPorClasse[];
  movimentacoes?: IMovimentacoes[];
  alocCustodiante?: IAlocCustodiante[];
  alocClasse?: IAlocClasse[];
  selectedBenchmarks: string[] | null;
  tabela_liquidez: (string | number)[][];
  loadingPercent: boolean;
  isPercentToggle: boolean;
  dadosPercentResumo?: [
    {
      classe: string;
      saldo: number;
      percent: number;
      mes_perf: number;
      ano_perf: number;
    },
  ];
}

const initialState: IResumoCartState = {
  isLoaded: false,
  isLoadedPercent: false,
  loading: false,
  obj_datas: {
    data_ini: '',
    data_fim: '',
    data_ini2: '',
  },
  resumo: {
    datas: {
      data_ini: '',
      data_fim: '',
    },
    saldoIni: '',
    saldoFim: '',
    impostospagos: '',
    rendimentoBruto: '',
    movimentacoes: '',
  },
  retorno: [],
  rendPorClasse: [],
  movimentacoes: [],
  alocClasse: [],
  alocCustodiante: [],
  selectedBenchmarks: null,
  tabela_liquidez: [],
  loadingPercent: false,
  isPercentToggle: false
};

export const resumoCartSlice = createSlice({
  name: 'resumoCart',
  initialState,
  reducers: {
    setSelectedBenchmarks: (state, action) => {
      // se estiver carregado com os benchmarks null, foram utilizados os benchmarks do CV_bench_carteira1
      if(state.isLoaded && state.selectedBenchmarks !== null){
        state.selectedBenchmarks = action.payload;
        if (state.selectedBenchmarks === action.payload) {
          state.isLoaded = false;
        }
      }else{  // se j� estiver carregado com os benchs da preferencia, n�o recarregar
        state.selectedBenchmarks = action.payload;
      }
    },
    resetSelectedBenchmarks: (state) => {
      state.selectedBenchmarks = initialState.selectedBenchmarks;
    },
    setIsPercentToggle: (state, action) => {
      state.isPercentToggle = action.payload;
    },
  },
  extraReducers: (builder) => {
    resetStateBuilder(builder, initialState, ['selectedBenchmarks']);

    builder.addCase(fetchResumoCart.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchResumoCart.fulfilled, (state, action) => {
      state.loading = false;
      state.isLoaded = true;
      state.obj_datas = action.payload.ResumoCart?.obj_datas;
      state.retorno = action.payload.ResumoCart?.retorno;
      state.rendPorClasse = action.payload.ResumoCart?.rendPorClasse;
      state.movimentacoes = action.payload.ResumoCart?.movimentacoes;
      state.resumo = action.payload.ResumoCart?.resumo;
      state.alocCustodiante = action.payload.ResumoCart?.alocCustodiante;
      state.alocClasse = action.payload.ResumoCart?.alocClasse;
      state.tabela_liquidez = action.payload.ResumoCart?.tabela_liquidez;
    });
    builder.addCase(fetchResumoCart.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(fetchResumoCartPerf.pending, (state) => {
      state.loadingPercent = true;
    });
    builder.addCase(fetchResumoCartPerf.fulfilled, (state, action) => {
      state.isLoadedPercent = true;
      state.loadingPercent = false;
      state.dadosPercentResumo = action.payload.ResumoCartPerf.perfPorClasse;
    });
    builder.addCase(fetchResumoCartPerf.rejected, (state) => {
      state.loadingPercent = false;
    });
  },
});

export const { setSelectedBenchmarks, resetSelectedBenchmarks, setIsPercentToggle } = resumoCartSlice.actions;

export const selectToken = (state: RootState) => state.preference.token;

export default resumoCartSlice.reducer;
